import React from 'react';
import { Link, graphql } from 'gatsby';

import {
    IntroHolder,
    TwoColumns,
    Sidebar,
    Reference,
    Content,
    IntroQuote,
} from '@bahaide/shared/components/InnerPage';

import InnerPage from '../../../components/InnerPage';

const FamilieKinder = ({ data }) => (
    <InnerPage
        title="Familie und Kinder"
        description="Die Familie als kleinste Einheit einer Gesellschaft"
    >
        <IntroHolder
            title="Familie und Kinder"
            subtitle="Die Familie als kleinste Einheit einer Gesellschaft"
            picture={data.introImage}
            breadcrumbs={{
                parent: 'Gemeinsames Handeln',
                'current-item': 'Familie und Kinder',
            }}
        >
            <IntroQuote href="/gemeinsames-handeln/familie-und-kinder/zitate">
                Beachte, wie leicht sich die Angelegenheiten einer Familie
                regeln lassen, wenn Einheit herrscht, welche Fortschritte die
                Familienmitglieder dann machen, wie erfolgreich sie in der Welt
                sind.
            </IntroQuote>
        </IntroHolder>

        <TwoColumns>
            <Content>
                <p>
                    Nach den Lehren Bahá’u’lláhs ist die Einheit der Menschheit
                    ein Prozess, der in der Familie als kleinste Einheit der
                    Gesellschaft beginnt. In ihr werden wir mit Eigenschaften
                    und Fähigkeiten vertraut gemacht, die uns in unserem Umgang
                    mit anderen Menschen prägen – das Teilen, ein liebevoller
                    Umgang, Hilfsbereitschaft und Gastfreundschaft sind nur
                    einige Beispiele. Ebenso lernen wir als erstes in der
                    Familie, wie Beziehungen zwischen Menschen entstehen und
                    gepflegt werden.
                </p>
                <div>
                    <p>
                        Um diese Fähigkeiten zu erlernen, ist es das Ziel einer
                        jeden Familie, alle Zellen dieser kleinen Einheit zu
                        entwickeln. Werden die jedem Menschen innewohnenden
                        Fähigkeiten und Eigenschaften durch eine liebevolle
                        Erziehung in der Familie gefördert, wecken sie bei den
                        Kindern schon früh den Wunsch, sich in das Leben und in
                        die Entwicklung der Gesellschaft aktiv einzubringen.
                    </p>
                    <p>
                        <img
                            alt=""
                            src={data.collage.childImageSharp.fluid.src}
                            className="aligncenter"
                        />
                    </p>
                    <p>
                        Gleichwertigkeit von Frau und Mann ist ein
                        gesellschaftlicher Prozess, der bereits bei der
                        Erziehung von Kindern ansetzt. Die Einigkeit in einer
                        Familie wird gefördert, wenn in wichtigen
                        Angelegenheiten Entscheidungen durch gemeinsame Beratung
                        aller Familienmitglieder getroffen werden.
                    </p>
                    <p>
                        Bahá’í sind bestrebt, ihre Familie nicht als isolierte
                        Einheit zu sehen, sondern sie an ihrem{' '}
                        <Link to="/gemeinsames-handeln/">
                            Engagement für die Gesellschaft
                        </Link>{' '}
                        teilnehmen zu lassen. So finden zum Beispiel in vielen
                        Orten und Nachbarschaften{' '}
                        <Link to="/gemeinsames-handeln/familie-und-kinder/kinderklasse/">
                            Kinderklassen
                        </Link>{' '}
                        statt, die der geistig-spirituellen Entwicklung der
                        Kinder dienen.
                    </p>
                </div>
                <Reference>
                    <p>1. ‘Abdu’l-Bahá, Briefe und Botschaften 221</p>
                </Reference>
            </Content>
            <Sidebar />
        </TwoColumns>
    </InnerPage>
);

export default FamilieKinder;

export const pageQuery = graphql`
    query {
        introImage: file(
            relativePath: { eq: "familie-und-kinder-feature.jpg" }
        ) {
            ...fluidImage
        }
        collage: file(relativePath: { eq: "FamilieKinder.m.png" }) {
            ...fluidImage
        }
    }
`;
